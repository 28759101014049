/**
 * Public Component - Wrapper for PrivateComponent with the publicOnly flag
 */

import React from 'react';
import PrivateComponent from './PrivateComponent';

const PublicComponent = props => <PrivateComponent {...props} publicOnly />;

export default PublicComponent;
