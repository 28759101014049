import qs from 'qs';
import history from '_platform/src/utils/history';

const pageQueryString = qs.parse(
  // Normalise key
  history.location.search.replace(/returnUrl/gi, 'returnUrl'),
  {
    ignoreQueryPrefix: true,
  }
);

export default pageQueryString.returnUrl;
