import React from 'react';
import PropTypes from 'prop-types';
import { styled, up, css } from '@smooth-ui/core-sc';

const EmbedContainer = styled.div`
  border: 1px solid #fff;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  ${up(
    'lg',
    css`
      margin-top: 60px;
    `
  )}

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const VimeoVideoEmbed = ({ videoId, hashParameter, title }) => {
  return (
    <EmbedContainer>
      <iframe
        src={`https://player.vimeo.com/video/${videoId}?h=${hashParameter}&title=0&byline=0&portrait=0`}
        frameBorder="0"
        allowFullScreen
        allow="autoplay; fullscreen; picture-in-picture"
        title={title}
      />
      <script src="https://player.vimeo.com/api/player.js"></script>
    </EmbedContainer>
  );
};

VimeoVideoEmbed.propTypes = {
  videoId: PropTypes.string.isRequired,
  hashParameter: PropTypes.string.isRequired,
  title: PropTypes.string,
};

VimeoVideoEmbed.defaultProps = {
  title: undefined,
};

export default VimeoVideoEmbed;
