import { styled } from '@smooth-ui/core-sc';
import PropTypes from 'prop-types';

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${p => (p.height ? p.height : '190px')};
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: ${p => (p.width ? p.width : '190px')};
`;

IconWrapper.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

IconWrapper.defaultProps = {
  height: undefined,
  width: undefined,
};

export default IconWrapper;
